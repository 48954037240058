import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallary from './pages/Gallary';
import {imageList, galleryHome} from './data/data';
import Login from './backend/components/Login';

const isHomePage = window.location.origin.includes('home') ? true : false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children:[{
      path: 'home',
      element:<Home/>
    },
    {
      path: 'gallary',
      element:<Gallary imgList={isHomePage ? galleryHome : imageList}/>
    },
    {
      path:'about-us',
      element:<About/>
    },
    {
      path:'contact-us',
      element:<Contact />
    },
    {
      path: '',
      element: <Home/>
    },
    {
      path: 'backend',
      element:<Login/>
    }
]
  }
])

root.render(
      <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from "axios";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

const Login = (()=>{
    const { register, handleSubmit, watch, formState: { errors } } = useForm<any>();
    const [message , setMessage] = useState<string>();
    
    const onSubmit: SubmitHandler<any> = data => {
        
            axios.post('http://localhost:8080/api/v1/login', data).then(res=>{
                console.log(res.status, res.data);
                setMessage(undefined);
            }).catch(e=>{
                console.log(e.response.data);
                setMessage(e.response.data.message);
            })
        
    };
    return (
        <div className="container mt-5">
        <div className="row justify-content-center">
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <form id="registrationForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label aria-label="email">
                                    Email
                                </label>
                                <input type="text" {...register('username', {required:true})}
                                       className="form-control" 
                                       id="email" 
                                       placeholder="Email" required />
                            </div>
                            <div className="form-group">
                                <label aria-label="password">
                                    Password
                                </label>
                                <input type="password"  {...register('password', {required: true})}
                                       className="form-control" 
                                       id="password" 
                                       placeholder="Password"
                                    required />
                            </div>
                            <button className="btn btn-danger">
                                Login
                            </button>
                            {
                                message &&
                                <div className="alert alert-danger">{message}</div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
})

export default Login;
import React from 'react';
import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import {Outlet} from 'react-router-dom';

function App() {
  const isBackend = window.location.pathname.includes('backend') ? true : false;
  console.log(window.location.pathname);
  return (
    <>    
      <Header />
    <main className="add-top">
      <Outlet></Outlet>
    </main>
      <Footer />
    </>
  );
}

export default App;

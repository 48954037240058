import React from 'react'
import Carousel from '../components/Carousel';
import Gallary from './Gallary';
import Contact from './Contact';
import About from './About';
import {imageList} from '../data/data';
import { carouselImages, galleryHome } from '../data/data';

const isHomePage = window.location.origin.includes('home') ? true : false;

const Home = () => {
    return (
        <>
            <Carousel carausel={carouselImages}/> 
            <About />
            <Gallary imgList={isHomePage ? galleryHome : imageList}/>
            <Contact/>
        </>
    )
}

export default Home
